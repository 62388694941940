/**
 * 숫자를 여섯자리수로 만들어주는 함수
 *
 * @param number 숫자
 * @param digit 자리수
 * @returns {string}
 */
export const makeNumberToSextupleDigit = (
  number: number,
  digit?: number
): string => {
  return String(number).padStart(digit || 6, '0');
};

/**
 * 소수점 길이 맞춰주는 함수
 */
export const setDecimalPlaces = (number: number, decimal: number): string => {
  return number.toFixed(decimal);
};

/**
 * 전화번호 하이픈(-) 넣어주는 함수
 */
export const insertHypensInPhoneNumber = (phoneNumber: string): string => {
  return (
    phoneNumber.slice(0, 3) +
    '-' +
    phoneNumber.slice(3, 7) +
    '-' +
    phoneNumber.slice(7)
  );
};
