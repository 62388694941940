import { lazy, Suspense, useEffect, useMemo } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { useAtom } from 'jotai';
import { ThemeProvider } from 'styled-components';
import { theme } from '@lib/styles/theme';
import GlobalStyles from '@lib/styles/global';
import { USER_PAGE_PATH } from '@lib/constants/user/common';
import { ADMIN_PAGE_PATH } from '@lib/constants/admin/common';
import {
  accessTokenAtomWithPersistence,
  roleAtomWithPersistence,
} from '@store/login';
import LayoutPage from '@pages/user/LayoutPage';
import {
  ErrorFallback,
  LoadingSpinner,
  MutatingSpinner,
  Toast,
} from '@components/@shared';
import PrivateRouteAdmin from './PrivateRouteAdmin';

const MainPage = lazy(() => import('./pages/user/MainPage'));
const DppPage = lazy(() => import('./pages/user/DppPage'));
const FreePickUpServicePage = lazy(
  () => import('./pages/user/FreePickUpServicePage')
);
const RepairServicePage = lazy(() => import('./pages/user/RepairServicePage'));
// Renewal
const HomePage = lazy(() => import('./pages/user/HomePage'));
const CarbonPage = lazy(() => import('./pages/user/CarbonPage'));
const BrandPage = lazy(() => import('./pages/user/BrandPage'));
const SettingPage = lazy(() => import('./pages/user/SettingPage'));

// [ADMIN]
const AdminLayoutPage = lazy(() => import('./pages/admin/LayoutPage'));
const AdminNotFoundPage = lazy(() => import('./pages/admin/NotFoundPage'));
const AdminLoginPage = lazy(() => import('./pages/admin/LoginPage'));
const AdminFindEmailPage = lazy(() => import('./pages/admin/FindEmailPage'));
const AdminFindPasswordPage = lazy(
  () => import('./pages/admin/FindPasswordPage')
);
const AdminSignUpPage = lazy(() => import('./pages/admin/SignUpPage'));
const AdminSignUpResultPage = lazy(
  () => import('./pages/admin/SignUpPage/ResultPage')
);
const AdminAccountInfoPage = lazy(
  () => import('./pages/admin/AccountInfoPage')
);
const AdminManagerAuthorityPage = lazy(
  () => import('./pages/admin/ManagerAuthorityPage')
);
const AdminBrandInfoPage = lazy(() => import('./pages/admin/BrandInfoPage'));
const AdminDashboardPage = lazy(() => import('./pages/admin/DashboardPage'));
const AdminManagersPage = lazy(() => import('./pages/admin/ManagersPage'));
const AdminBrandListPage = lazy(() => import('./pages/admin/BrandListPage'));
const AdminBrandDetailPage = lazy(
  () => import('./pages/admin/BrandDetailPage')
);
const AdminBrandUploadPage = lazy(
  () => import('./pages/admin/BrandUploadPage')
);
const AdminBrandUpdatePage = lazy(
  () => import('./pages/admin/BrandUpdatePage')
);
const AdminProductImageListPage = lazy(
  () => import('./pages/admin/ProductImageListPage')
);
const AdminProductImageUploadPage = lazy(
  () => import('./pages/admin/ProductImageUploadPage')
);
const AdminCareLabelImageListPage = lazy(
  () => import('./pages/admin/CareLabelImageListPage')
);
const AdminCareLabelListPage = lazy(
  () => import('./pages/admin/CareLabelListPage')
);
const AdminCareLabelCreatePage = lazy(
  () => import('./pages/admin/CareLabelCreatePage')
);
const AdminCareLabelDesignPage = lazy(
  () => import('./pages/admin/CareLabelDesignPage')
);
const AdminCareLabelImageUploadPage = lazy(
  () => import('./pages/admin/CareLabelImageUploadPage')
);
const AdminDppInfoListPage = lazy(
  () => import('./pages/admin/DppInfoListPage')
);
const AdminDppInfoDetailPage = lazy(
  () => import('./pages/admin/DppInfoDetailPage')
);
const AdminDppInfoExcelUploadPage = lazy(
  () => import('./pages/admin/DppInfoExcelUploadPage')
);
const AdminDppInfoUploadPage = lazy(
  () => import('./pages/admin/DppInfoUploadPage')
);
const AdminDppInfoUpdatePage = lazy(
  () => import('./pages/admin/DppInfoUpdatePage')
);

export default function App() {
  const location = useLocation();
  const [accessToken] = useAtom(accessTokenAtomWithPersistence);
  const [role] = useAtom(roleAtomWithPersistence);

  const isAdmin = useMemo(() => {
    const adminRole = role === 'brand' || role === 'admin';
    return !!accessToken && adminRole;
  }, [accessToken, role]);

  const isUser = useMemo(() => {
    return role === 'user';
  }, [role]);

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, [location.pathname]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Toast />
      <MutatingSpinner />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route path={USER_PAGE_PATH.index} element={<LayoutPage />}>
              <Route path={USER_PAGE_PATH.product.index}>
                <Route
                  path={USER_PAGE_PATH.product.main}
                  element={<HomePage />}
                />
                <Route
                  path={USER_PAGE_PATH.product.carbon}
                  element={<CarbonPage />}
                />
                <Route
                  path={USER_PAGE_PATH.product.brand}
                  element={<BrandPage />}
                />
                <Route
                  path={USER_PAGE_PATH.product.setting}
                  element={<SettingPage />}
                />
                {/* <Route
                 path={USER_PAGE_PATH.product.main}
                  element={<MainPage />}
                 /> */}
                <Route
                  path={USER_PAGE_PATH.product.dppInfo}
                  element={<DppPage />}
                />
                <Route
                  path={USER_PAGE_PATH.product.freePickUpService}
                  element={<FreePickUpServicePage />}
                />
                <Route
                  path={USER_PAGE_PATH.product.repairService}
                  element={<RepairServicePage />}
                />
              </Route>
            </Route>
            {/* Admin */}
            {/* Admin - 로그인 */}
            <Route
              path={ADMIN_PAGE_PATH.guest.login}
              element={<AdminLoginPage />}
            />
            {/* Admin - 이메일 찾기 */}
            <Route
              path={ADMIN_PAGE_PATH.guest.findEmail}
              element={<AdminFindEmailPage />}
            />
            {/* Admin - 비밀번호 찾기 */}
            <Route
              path={ADMIN_PAGE_PATH.guest.findPassword}
              element={<AdminFindPasswordPage />}
            />
            {/* Admin - 회원가입 */}
            <Route
              path={ADMIN_PAGE_PATH.guest.signup}
              element={<AdminSignUpPage />}
            />
            {/* Admin - 회원가입 결과 */}
            <Route
              path={ADMIN_PAGE_PATH.guest.signupResult}
              element={<AdminSignUpResultPage />}
            />
            {/* Admin - 회원정보 조회/수정 */}
            <Route
              path={ADMIN_PAGE_PATH.mypage.account}
              element={<AdminAccountInfoPage />}
            />
            {/* Admin - 매니저의 브랜드 관리 (role이 user 또는 manager일 때만 접근 가능) */}
            <Route
              path={ADMIN_PAGE_PATH.mypage.authority}
              element={
                <PrivateRouteAdmin isAuthenticated={!isAdmin}>
                  <AdminManagerAuthorityPage />
                </PrivateRouteAdmin>
              }
            />
            {/* Admin - 케어라벨 디자인 SaaS */}
            <Route
              path={`${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.careLabel.index}`}
            >
              <Route
                path={`${ADMIN_PAGE_PATH.careLabel.design}/:no`}
                element={<AdminCareLabelDesignPage />}
              />
            </Route>
            <Route
              path={ADMIN_PAGE_PATH.index}
              element={
                <PrivateRouteAdmin isAuthenticated={!isUser}>
                  <AdminLayoutPage />
                </PrivateRouteAdmin>
              }
            >
              {/* Admin - 케어라벨 */}
              <Route
                path={`${ADMIN_PAGE_PATH.index}/${ADMIN_PAGE_PATH.careLabel.index}`}
              >
                <Route index element={<AdminCareLabelListPage />} />
                <Route
                  path={ADMIN_PAGE_PATH.careLabel.create}
                  element={<AdminCareLabelCreatePage />}
                />
              </Route>
              <Route index element={<AdminNotFoundPage />} />
              {/* Admin - 대시보드 */}
              <Route path={ADMIN_PAGE_PATH.dashboard.index}>
                <Route
                  path={ADMIN_PAGE_PATH.dashboard.list}
                  element={<AdminDashboardPage />}
                />
              </Route>
              {/* Admin - 브랜드 정보 */}
              <Route path={ADMIN_PAGE_PATH.brand.index}>
                <Route index element={<AdminBrandInfoPage />} />
                {/* Admin - 매니저 관리 (brand admin only) */}
                <Route
                  path={ADMIN_PAGE_PATH.brand.managers}
                  element={
                    <PrivateRouteAdmin isAuthenticated={isAdmin}>
                      <AdminManagersPage />
                    </PrivateRouteAdmin>
                  }
                />
                <Route
                  path={ADMIN_PAGE_PATH.brand.list}
                  element={<AdminBrandListPage />}
                />
                <Route
                  path={ADMIN_PAGE_PATH.brand.detail}
                  element={<AdminBrandDetailPage />}
                />
                <Route
                  path={ADMIN_PAGE_PATH.brand.upload}
                  element={<AdminBrandUploadPage />}
                />
                <Route
                  path={ADMIN_PAGE_PATH.brand.update}
                  element={<AdminBrandUpdatePage />}
                />
              </Route>
              {/* Admin - 제품 이미지 */}
              <Route path={ADMIN_PAGE_PATH.productImage.index}>
                <Route index element={<AdminProductImageListPage />} />
                <Route
                  path={ADMIN_PAGE_PATH.productImage.upload}
                  element={<AdminProductImageUploadPage />}
                />
              </Route>
              {/* Admin - 케어라벨 이미지 */}
              <Route path={ADMIN_PAGE_PATH.careLabelImage.index}>
                <Route index element={<AdminCareLabelImageListPage />} />
                <Route
                  path={ADMIN_PAGE_PATH.careLabelImage.upload}
                  element={<AdminCareLabelImageUploadPage />}
                />
              </Route>
              {/* Admin - DPP 정보 */}
              <Route path={ADMIN_PAGE_PATH.dppInfo.index}>
                <Route index element={<AdminDppInfoListPage />} />
                <Route
                  path={ADMIN_PAGE_PATH.dppInfo.detail}
                  element={<AdminDppInfoDetailPage />}
                />
                <Route
                  path={ADMIN_PAGE_PATH.dppInfo.excelUpload}
                  element={<AdminDppInfoExcelUploadPage />}
                />
                <Route
                  path={ADMIN_PAGE_PATH.dppInfo.upload}
                  element={<AdminDppInfoUploadPage />}
                />
                <Route
                  path={ADMIN_PAGE_PATH.dppInfo.update}
                  element={<AdminDppInfoUpdatePage />}
                />
              </Route>
              <Route path="*" element={<AdminNotFoundPage />} />
            </Route>
            {/* 404 Not Found */}
            <Route path="*" element={<div>404 Not Found</div>} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
