import styled from 'styled-components';

interface ButtonProps {
  label: string | JSX.Element;
  onClickBtn?: () => void;
  disabled?: boolean;
}

export default function Button({ label, onClickBtn, disabled }: ButtonProps) {
  return (
    <StyledButton
      type={onClickBtn ? 'button' : 'submit'}
      disabled={!!disabled}
      {...(onClickBtn && { onClick: onClickBtn })}
    >
      <span>{label}</span>
    </StyledButton>
  );
}

const StyledButton = styled.button`
  padding: 10px;
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.black};

  &:disabled {
    color: ${(props) => props.theme.colors.gray400};
    background-color: ${(props) => props.theme.colors.gray100};
    cursor: default;
  }
`;
