import {
  Layer,
  LayoutPosition,
  AlignDirection,
  AlignConfig,
  HorizontalAlignDirection,
  VeritcalAlignDirection,
} from '@customTypes/admin/careLabel';

// 실제 레이블 크기 계산
const calculateActualSize = (config: AlignConfig) => {
  const { width, height, side_margin, seam_margin, option } = config;
  return {
    width: width - side_margin * 2,
    height: height - (option === 'fold' ? seam_margin * 2 : seam_margin),
  };
};

// 수평 정렬 위치 계산
const calculateHorizontalPosition = (
  direction: HorizontalAlignDirection,
  layerWidth: number,
  containerWidth: number
): number => {
  switch (direction) {
    case 'left':
      return 0;
    case 'center':
      return Math.ceil(containerWidth / 2 - layerWidth / 2);
    case 'right':
      return containerWidth - layerWidth;
    default:
      return 0;
  }
};

// 수직 정렬 위치 계산
const calculateVerticalPosition = (
  direction: VeritcalAlignDirection,
  layerHeight: number,
  containerHeight: number
): number => {
  switch (direction) {
    case 'top':
      return 0;
    case 'middle':
      return Math.ceil(containerHeight / 2 - layerHeight / 2);
    case 'bottom':
      return containerHeight - layerHeight;
    default:
      return 0;
  }
};

const isHorizontalAlign = (
  direction: AlignDirection
): direction is HorizontalAlignDirection => {
  return ['left', 'center', 'right'].includes(direction);
};

const isVerticalAlign = (
  direction: AlignDirection
): direction is VeritcalAlignDirection => {
  return ['top', 'middle', 'bottom'].includes(direction);
};

// 그리드 기준 단일 레이어 정렬 위치 계산
export const calculateSingleLayerPosition = (
  layer: Layer,
  direction: AlignDirection,
  config: AlignConfig
) => {
  const { width: containerWidth, height: containerHeight } =
    calculateActualSize(config);
  const position: Partial<LayoutPosition> = {};

  if (isHorizontalAlign(direction)) {
    position.x = calculateHorizontalPosition(
      direction,
      layer.layout.w,
      containerWidth
    );
  }

  if (isVerticalAlign(direction)) {
    position.y = calculateVerticalPosition(
      direction,
      layer.layout.h,
      containerHeight
    );
  }

  return position;
};

/**
 *
 */

// 공통 위치 계산 타입 정의
type CalculatePositionProps = {
  value: number;
  size: number;
  boundaryStart: number;
  boundaryEnd: number;
};

// 공통 정렬 계산 함수
const calculatePosition = ({
  value,
  size,
  boundaryStart,
  boundaryEnd,
}: CalculatePositionProps): number => {
  const total = boundaryEnd - boundaryStart;
  const center = boundaryStart + total / 2;

  return Math.ceil(center - size / 2);
};

// 다중 레이어 정렬을 위한 경계값 계산
const calculateBoundary = (
  layers: Layer[],
  getPosition: (layer: Layer) => number,
  getSize: (layer: Layer) => number
) => {
  const positions = layers.map(getPosition);
  const start = Math.min(...positions);
  const end = Math.max(
    ...layers.map((layer) => getPosition(layer) + getSize(layer))
  );

  return { start, end };
};

// 선택된 레이어들 간의 정렬 위치 계산
export const calculateMultiLayerPosition = (
  layers: Layer[],
  direction: AlignDirection
): Record<string, Partial<LayoutPosition>> => {
  if (layers.length <= 1) return {};

  const positions: Record<string, Partial<LayoutPosition>> = {};

  if (isHorizontalAlign(direction)) {
    const { start: leftMost, end: rightMost } = calculateBoundary(
      layers,
      (layer) => layer.layout.x,
      (layer) => layer.layout.w
    );

    layers.forEach((layer) => {
      const basePosition = {
        value: layer.layout.x,
        size: layer.layout.w,
        boundaryStart: leftMost,
        boundaryEnd: rightMost,
      };

      switch (direction) {
        case 'left':
          positions[layer.id] = { x: leftMost };
          break;
        case 'center':
          positions[layer.id] = {
            x: calculatePosition(basePosition),
          };
          break;
        case 'right':
          positions[layer.id] = {
            x: rightMost - layer.layout.w,
          };
          break;
      }
    });
  }

  if (isVerticalAlign(direction)) {
    const { start: topMost, end: bottomMost } = calculateBoundary(
      layers,
      (layer) => layer.layout.y,
      (layer) => layer.layout.h
    );

    layers.forEach((layer) => {
      const basePosition = {
        value: layer.layout.y,
        size: layer.layout.h,
        boundaryStart: topMost,
        boundaryEnd: bottomMost,
      };

      switch (direction) {
        case 'top':
          positions[layer.id] = { y: topMost };
          break;
        case 'middle':
          positions[layer.id] = {
            y: calculatePosition(basePosition),
          };
          break;
        case 'bottom':
          positions[layer.id] = {
            y: bottomMost - layer.layout.h,
          };
          break;
      }
    });
  }

  return positions;
};

// 통합된 정렬 함수 export
export const calculateAlignPosition = (
  layers: Layer[],
  direction: AlignDirection,
  config?: AlignConfig
): Record<string, Partial<LayoutPosition>> => {
  if (layers.length === 0) return {};

  if (layers.length === 1 && config) {
    // 단일 레이어 정렬
    return {
      [layers[0].id]: calculateSingleLayerPosition(
        layers[0],
        direction,
        config
      ),
    };
  }

  // 다중 레이어 정렬
  return calculateMultiLayerPosition(layers, direction);
};
