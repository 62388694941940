import { MutableRefObject } from 'react';

/**
 * 다중 Input 입력 값 모두 초기화해주는 함수
 * @param inputListRef MutableRefObject<(HTMLInputElement | null)[]
 */
export const resetAllInputsValue = (
  inputListRef: MutableRefObject<(HTMLInputElement | null)[]>
) => {
  const searchInputElemList = inputListRef.current;

  for (let i = 0; i < searchInputElemList.length; i++) {
    const searchInputElem = searchInputElemList[i];
    if (!searchInputElem) return;

    searchInputElem.value = '';
  }
};
