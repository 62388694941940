import { ADMIN_PAGE_PATH } from '@lib/constants/admin/common';
import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

interface PrivateRouteAdminProps extends PropsWithChildren {
  isAuthenticated: boolean;
}

export default function PrivateRouteAdmin({
  children,
  isAuthenticated,
}: PrivateRouteAdminProps) {
  return (
    <>
      {isAuthenticated ? (
        children
      ) : (
        <Navigate
          to={'/'}
          state={{ prevUrl: window.location.href }}
          //   replace
        />
      )}
    </>
  );
}
