import {
  BRAND_ADMIN_NAV_LIST,
  BRAND_MANAGER_NAV_LIST,
  SUPER_ADMIN_NAV_LIST,
} from '@lib/constants/admin/common';
import { NullishRole } from '@customTypes/admin';

export const getNavListByRole = (role: NullishRole) => {
  switch (role) {
    case 'admin':
      return SUPER_ADMIN_NAV_LIST;
    case 'brand':
      return BRAND_ADMIN_NAV_LIST;
    case 'manager':
      return BRAND_MANAGER_NAV_LIST;
    default:
      return [];
    //   throw new Error('접근 권한이 없습니다.');
  }
};
